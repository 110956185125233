<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="智能输液监控系统" describe="智能输液监控系统采用物联传感技术，基于多网合一基站无线传输，可实时监测输液滴速及输液完毕后自动报警并推送到护士站，同时支持实时获取 HIS中的数据信息，并通过终端设备更新显示病区床位患者信息及输液状态。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/infusion1.png" class="img1" alt="">
        <img src="@/assets/product/infusion2.png" class="img2" alt="">
        <img src="@/assets/product/infusion3.png" class="img3" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: 'SmartWard',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1220px;
    margin: 0 auto;
    .img1 {
      width: 100%;
      height: 724px;
    }
    .img2 {
      width: 100%;
      height: 317px;
    }
    .img3 {
      width: 100%;
      height: 685px;
    }
  }
}
</style>
